// The axios wrapper
import axios from "axios";
import { resetCountTime } from "../Helper/CommonThings";
// import { deleteCookie } from "../Helper/CookieHelper";

const host_name = window.location.hostname
let domain = null
let domain2 = null
let type = null

if (host_name.includes('bbparam.com')) {
  type = "prod"
  domain = 'https://api.bbparam.com'

} else if (host_name.includes('qa.param.bbedc.dev')) {
  type = "qa"

  domain = 'https://api.qa.bbparam.com'

} else if (host_name.includes('param.bbedcindia.com')) {
  // domain = 'https://api.bbparam.com'
  // domain = 'http://param.bbedcindia.com:8000'
  domain = 'http://yaagesh-dev.centralindia.cloudapp.azure.com:8000'
  // domain = 'http://gnidct.bbedcindia.com:8000'

} else if (host_name.includes('gnidct.bbedcindia.com')) {

  // domain = 'https://api.bbparam.com'
  // domain = 'http://param.bbedcindia.com:8000'
  // domain = 'http://gnidct.bbedcindia.com:8000'
  domain = 'http://gnidct.bbedcindia.com:7000'

}
else if (host_name.includes('santhosh-dev.centralindia.cloudapp.azure.com')) {
  type = "dev"
  // domain = 'https://api.bbparam.com'
  // domain = 'http://param.bbedcindia.com:8000'
  // domain = 'http://gnidct.bbedcindia.com:8000'
  domain = 'http://jayashree-dev.centralindia.cloudapp.azure.com:8000'
  // domain = 'http://gnidct.bbedcindia.com:7000'
}
else if (host_name.includes('sakthi-dev.centralindia.cloudapp.azure.com')) {

  // domain = 'https://api.bbparam.com'
  // domain = 'http://param.bbedcindia.com:8000'
  // domain = 'http://gnidct.bbedcindia.com:8000'
  domain = 'http://sakthi-dev.centralindia.cloudapp.azure.com:8000'
  // domain = 'http://gnidct.bbedcindia.com:7000'
}
else if (host_name.includes('paul-dev.centralindia.cloudapp.azure.com')) {

  // domain = 'https://api.bbparam.com'
  // domain = 'http://param.bbedcindia.com:8000'
  // domain = 'http://gnidct.bbedcindia.com:8000'
  domain = 'http://shweetha-dev.centralindia.cloudapp.azure.com:8003'
  // domain = 'http://gnidct.bbedcindia.com:7000'

} else if (host_name.includes('yaagesh-dev.centralindia.cloudapp.azure.com')) {

  // domain = 'https://api.bbparam.com'
  // domain = 'https://api.qa.bbparam.com'
  // domain = 'http://gnidct.bbedcindia.com:8000'
  domain = 'http://yaagesh-dev.centralindia.cloudapp.azure.com:8000'
  // domain = 'http://gnidct.bbedcindia.com:7000'

} else if (host_name.includes('six-sides-sin.loca.lt')) {

  // domain = 'https://bb-rmp.appspot.com'
  // domain = 'http://param.bbedcindia.com:8000'
  // domain = 'http://gnidct.bbedcindia.com:8000'
  // domain = 'http://yaagesh-dev.centralindia.cloudapp.azure.com:8000'
  domain = 'https://four-banks-jog.loca.lt'
  // domain = 'http://gnidct.bbedcindia.com:7000'

} else {
  // domain = 'http://param.bbedcindia.com:8000'
  domain = 'http://yaagesh-dev.centralindia.cloudapp.azure.com:8000'
  // domain = 'https://reporting-pro.bbparam.com'

}

if (domain.includes('api.bbparam.com')) {
  // domain2 = 'https://api.bbparam.com'
  domain2 = 'https://reporting-pro.bbparam.com'
} else {
  domain2 = domain
}

export function getApiDomain() {
  return domain
}

export function getApiDomain2() {
  return domain2
}

export function getBlobSASURL() {
  if (type === 'qa') {
    return {
      domain: "https://paramstorageqa.blob.core.windows.net",
      cdn_domain: "https://paramstorageqa-hrgxdvgycdbpfjct.z01.azurefd.net",
      connection_string: "?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2025-12-31T13:58:47Z&st=2023-11-09T05:58:47Z&spr=https,http&sig=jUQj1bAQFe%2FoAHNQRKhwwj%2BaUw%2FxBH5rKdDWz9Yv%2FI8%3D"
    }
  } else if (type === 'prod') {
    return {
      domain: "https://paramstorageprod.blob.core.windows.net",
      cdn_domain: "https://paramstorageprod-fdd0anhugcd3eddk.a01.azurefd.net",
      connection_string: "?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2026-01-19T18:31:50Z&st=2024-01-18T10:31:50Z&spr=https,http&sig=rGk%2BUgnXXCnrmOAMBmlt1Jm2BZ67Uu141zeo74jrNE0%3D"
    }
  } else if (type === 'dev') {
    return {
      domain: "https://paramstorageqa.blob.core.windows.net",
      cdn_domain: "https://paramstorageqa-hrgxdvgycdbpfjct.z01.azurefd.net",
      connection_string: "?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2025-12-31T13:58:47Z&st=2023-11-09T05:58:47Z&spr=https,http&sig=jUQj1bAQFe%2FoAHNQRKhwwj%2BaUw%2FxBH5rKdDWz9Yv%2FI8%3D"
    }
  } else {
    return null
  }
}

class ServiceClass {
  constructor() {
    let service = null;

    service = axios.create({
      headers: {
        // Authorization: this.getAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json"
      },
    });
    axios.defaults.timeout = 1000 * 600;
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
    this.state = {
      statusCode: null
    };
    // resetCountTime();
  }
  // async getAccessToken() {
  //   try {
  //     const value = await localStorage.getItem("id_token");
  //     if (value !== null) {
  //       return value;
  //     } else {
  //       return "";
  //     }
  //   } catch (error) {
  //     return "";
  //   }
  // }

  // handleSuccess(response) {
  //   return response;
  // }


  // handleError = error => {
  //   // console.log(error.response.status)
  //   switch (error.response.status) {
  //     case 401:
  //       // deleteCookie("bb_ts_token");
  //       // deleteCookie("bb_ts_user");
  //       // deleteCookie("bb_ts_access");
  //       if (window.location.pathname !== "/") {
  //         window.location.href = "/"
  //       }

  //       break;
  //     case 409:
  //       console.log(error);
  //       //alert(error.response.message);
  //       console.log("409: Something went wrong");
  //       break;
  //     case 404:
  //       console.log("404: Something went wrong!");
  //       break;
  //     case 500:
  //       console.log("500: Something went wrong!");
  //       break;
  //     case 502:
  //       console.log("502: Something went wrong!");
  //       break;
  //     default:
  //       // console.log(error);
  //       break;
  //   }
  // };


  get(path, options) {
    resetCountTime(path);
    return this.service
      .get(domain + path, {
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }

  patch(path, options) {
    resetCountTime(path);
    return this.service
      .request({
        method: "PATCH",
        url: domain + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }

  update(path, options) {
    resetCountTime(path);
    return this.service
      .request({
        method: "UPDATE",
        url: domain + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }

  post(path, options) {
    resetCountTime(path);
    return this.service
      .request({
        method: "POST",
        url: domain + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }

  put(path, options) {
    resetCountTime(path);
    return this.service
      .request({
        method: "PUT",
        url: domain + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }

  delete(path, options) {
    resetCountTime(path);
    return this.service
      .request({
        method: "DELETE",
        url: domain + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }








  get2(path, options) {
    // resetCountTime(path);
    return this.service
      .get(domain2 + path, {
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => {
        if (error.response.status === 403) {
          this.get(path, options)
        } else {
          Promise.reject(error.response)
        }
      });
  }

  patch2(path, options) {
    // resetCountTime(path);
    return this.service
      .request({
        method: "PATCH",
        url: domain2 + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }

  update2(path, options) {
    // resetCountTime(path);
    return this.service
      .request({
        method: "UPDATE",
        url: domain2 + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }

  post2(path, options) {
    // resetCountTime(path);
    return this.service
      .request({
        method: "POST",
        url: domain2 + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }

  put2(path, options) {
    // resetCountTime(path);
    return this.service
      .request({
        method: "PUT",
        url: domain2 + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }

  delete2(path, options) {
    // resetCountTime(path);
    return this.service
      .request({
        method: "DELETE",
        url: domain2 + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }

}

const Service = new ServiceClass()

export default Service;
